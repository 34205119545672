import React from 'react';
import Layout from '../../components/Layout/Layout';
import Home from '../../components/Home';

function Index() {
  return (
    <div className="column-flexed-wrapper">
      <Layout>
        <Home />
      </Layout>
    </div>
  );
}

export default Index;
